import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Time } from './Time';
import './game.css';
import pikachu from '../assets/pikachu.gif';
import alien from '../assets/alien.gif';
import jesus from '../assets/jesus.gif';

localStorage.setItem("numeroAciertos", 0);

let listaPalabrasUtilizadas = [];

let intentos = 0;

let tiempoRestante = 0;

export const Game = ({ palabras, option = "normal", timeLeftParameter }) => {

    const [firstTimeLeft, setFirstTimeLeft] = useState(5);

    const [timeLeft, setTimeLeft] = useState(timeLeftParameter);

    const [randomWord, setRandomWord] = useState(palabras[Math.floor(Math.random() * palabras.length)]);

    const [numeroAciertos, setNumeroAciertos] = useState(Number(localStorage.getItem("numeroAciertos")));

    const [gameFinish, setGameFinish] = useState(false);

    const [winner, setWinner] = useState(false);

    useEffect(() => {
        if (numeroAciertos === palabras.length) {

            if (option === 'facil') {
                let partidasFacilesGanadas = localStorage.getItem("partidasFacilesGanadas");
                if (partidasFacilesGanadas == null) {
                    localStorage.setItem("partidasFacilesGanadas", 1);
                } else {
                    localStorage.setItem("partidasFacilesGanadas", Number(partidasFacilesGanadas) + 1);
                }
            } else if (option === 'normal') {
                let partidasNormalesGanadas = localStorage.getItem("partidasNormalesGanadas");
                if (partidasNormalesGanadas == null) {
                    localStorage.setItem("partidasNormalesGanadas", 1);
                } else {
                    localStorage.setItem("partidasNormalesGanadas", Number(partidasNormalesGanadas) + 1);
                }
            } else if (option === 'dificil') {
                let partidasDificilesGanadas = localStorage.getItem("partidasDificilesGanadas");
                if (partidasDificilesGanadas == null) {
                    localStorage.setItem("partidasDificilesGanadas", 1);
                } else {
                    localStorage.setItem("partidasDificilesGanadas", Number(partidasDificilesGanadas) + 1);
                }
            } else if (option === 'extremo') {
                let partidasExtremasGanadas = localStorage.getItem("partidasExtremasGanadas");
                if (partidasExtremasGanadas == null) {
                    localStorage.setItem("partidasExtremasGanadas", 1);
                } else {
                    localStorage.setItem("partidasExtremasGanadas", Number(partidasExtremasGanadas) + 1);
                }
            } 

            tiempoRestante = timeLeft;
            setWinner(true);
            setGameFinish(true);
        } else {
            if (numeroAciertos >= 1) {
                // listaPalabrasUtilizadas.push(randomWord);
                // let nuevaPalabra = palabras[Math.floor(Math.random() * palabras.length)];
                // console.log(nuevaPalabra);
                // while (listaPalabrasUtilizadas.some(v => nuevaPalabra.includes(v))) {
                //     nuevaPalabra = palabras[Math.floor(Math.random() * palabras.length)];
                // }
                // console.log("listaPU", listaPalabrasUtilizadas);
                // console.log("nuevaPalabra escogida");
                // setRandomWord(nuevaPalabra);
                // console.log(randomWord);

                listaPalabrasUtilizadas.push(randomWord);

                let listaPalabrasUtilizadasTemp = [...listaPalabrasUtilizadas];

                let palabrasTemp = [...palabras];

                let palabrasPorUtilizar = palabrasTemp.filter(function (item) {
                    return listaPalabrasUtilizadasTemp.indexOf(item) === -1;
                })

                // console.log(palabrasPorUtilizar);

                let nuevaPalabra = palabrasPorUtilizar[Math.floor(Math.random() * palabrasPorUtilizar.length)];

                setRandomWord(nuevaPalabra);
                // console.log(randomWord);
            }
        }
    }, [numeroAciertos]);

    useEffect(() => {

        listaPalabrasUtilizadas = [];

        for (let i = 0; i < ref.length; i++) {

            ref[i].current.classList.add("text-white");

            ref[i].current.classList.remove("text-primary");

            ref[i].current.firstChild.classList.add("display_none");

            ref[i].current.firstChild.classList.toggle('flip_card_true');

            for (let i = 0; i < ref.length; i++) {
                ref[i].current.classList.remove("text-white");
            }

            for (let i = 0; i < ref.length; i++) {
                ref[i].current.classList.add("pointer_events");
            }

            let object = [];
            for (let i = 0; i < palabras.length; i++) {
                object.push({ id: i + 1, value: false });
            }

            setNoText(object);

        }

        try {

            if (option === 'facil') {
                let partidasFacilesJugadas = localStorage.getItem("partidasFacilesJugadas");
                if (partidasFacilesJugadas == null) {
                    localStorage.setItem("partidasFacilesJugadas", 1);
                } else {
                    localStorage.setItem("partidasFacilesJugadas", Number(partidasFacilesJugadas) + 1);
                }
            } else if (option === 'normal') {
                let partidasNormalesJugadas = localStorage.getItem("partidasNormalesJugadas");
                if (partidasNormalesJugadas == null) {
                    localStorage.setItem("partidasNormalesJugadas", 1);
                } else {
                    localStorage.setItem("partidasNormalesJugadas", Number(partidasNormalesJugadas) + 1);
                }
            } else if (option === 'dificil') {
                let partidasDificilesJugadas = localStorage.getItem("partidasDificilesJugadas");
                if (partidasDificilesJugadas == null) {
                    localStorage.setItem("partidasDificilesJugadas", 1);
                } else {
                    localStorage.setItem("partidasDificilesJugadas", Number(partidasDificilesJugadas) + 1);
                }
            } else if (option === 'extremo') {
                let partidasExtremasJugadas = localStorage.getItem("partidasExtremasJugadas");
                if (partidasExtremasJugadas == null) {
                    localStorage.setItem("partidasExtremasJugadas", 1);
                } else {
                    localStorage.setItem("partidasExtremasJugadas", Number(partidasExtremasJugadas) + 1);
                }
            }

            setTimeout(() => {

                for (let i = 0; i < ref.length; i++) {
                    for (let i = 0; i < ref.length; i++) {
                        ref[i].current.classList.add("text-primary");

                        ref[i].current.firstChild.classList.remove("display_none");

                        ref[i].current.firstChild.classList.remove("flip_card_true");
                    }

                    let object = [];
                    for (let i = 0; i < palabras.length; i++) {
                        object.push({ id: i + 1, value: true });
                    }

                    setNoText(object);

                    for (let i = 0; i < ref.length; i++) {
                        ref[i].current.classList.remove("pointer_events");
                    }

                }
            }, 5000);
        } catch (error) {
            console.log(error);
        }

        listaPalabrasUtilizadas = [];

        return () => {
            clearTimeout();
        }

    }, [])

    const handleClickButton = (e) => {

        let id = e.target.id;
        let text = palabras[id - 1];
        // console.log(text);

        const span = e.target.firstChild;

        if (text === randomWord) {

            // console.log("Acierto");
            const object = { id: Number(id), value: false };
            // console.log(object);

            let newArray = [...noText];
            if (newArray[id - 1].value === false) {
                return;
            }

            localStorage.setItem("numeroAciertos", numeroAciertos + 1);
            // console.log(localStorage.getItem("numeroAciertos"));
            setNumeroAciertos(num => num + 1);

            span.classList.toggle("display_none");

            newArray[id - 1] = object;

            // console.log(id - 1);

            setNoText([...newArray]);

            e.target.classList.add("text-white");

            e.target.classList.remove("btn-primary");

            e.target.classList.add("btn-success");

            e.target.classList.remove("text-primary");

            e.target.classList.add("pointer_events");

            // console.log(e.target);

            // console.log(noText);
        } else {

            intentos++;

            listaPalabrasUtilizadas = [];

            setNumeroAciertos(0);
            localStorage.setItem("numeroAciertos", 0);

            // console.log("Fallo");
            const object = { id: Number(id), value: false };
            // console.log(object);

            let newArray = [...noText];
            newArray[id - 1] = object;

            // console.log(id - 1);

            setNoText([...newArray]);

            e.target.classList.remove("btn-primary");

            e.target.classList.remove("text-primary");

            e.target.classList.add("text-white");

            e.target.classList.add("btn-danger");

            span.classList.add("display_none");

            span.classList.add("text-white");

            span.classList.toggle('flip_card_true');

            // for (let i = 0; i < ref.length; i++) {
            //     ref[i].current.classList.remove("text-white");
            // }

            for (let i = 0; i < ref.length; i++) {
                ref[i].current.classList.add("pointer_events");
            }


            setTimeout(() => {

                try {

                    for (let i = 0; i < ref.length; i++) {
                        ref[i].current.classList.add("text-primary");

                        ref[i].current.firstChild.classList.remove("display_none");

                        ref[i].current.firstChild.classList.remove("flip_card_true");

                        ref[i].current.classList.remove("btn-danger");

                        ref[i].current.classList.remove("btn-success");

                        ref[i].current.classList.add("btn-primary");

                    }

                    let object = [];
                    for (let i = 0; i < palabras.length; i++) {
                        object.push({ id: i + 1, value: true });
                    }

                    setNoText(object);

                    for (let i = 0; i < ref.length; i++) {
                        ref[i].current.classList.remove("pointer_events");
                    }

                    listaPalabrasUtilizadas = [];

                } catch (error) {
                    console.log(error);
                }

            }, 2000);

        }

    }

    const [noText, setNoText] = useState([
        { id: 1, value: true },
        { id: 2, value: true },
        { id: 3, value: true },
        { id: 4, value: true },
        { id: 5, value: true },
        { id: 6, value: true },
        { id: 7, value: true },
        { id: 8, value: true },
        { id: 9, value: true },
    ])

    const handleReset = () => {
        window.location.reload();
    }

    const ref = useMemo(() => Array(palabras.length).fill(0).map(i => React.createRef()), []);

    return (
        <div className="container">
            {
                !winner && timeLeft > 0 ?
                    (
                        <div className="text-center mr-auto ml-auto mt-4">
                            <button onClick={handleReset} className="btn btn-secondary w-auto m-auto mt-3">
                                Reset
                            </button>
                        </div>
                    ) : null
            }
            {!gameFinish ? (
                <>
                    {timeLeft > 0 ? (
                        <h4 className="text-center mt-5">
                            <Time timeLeft={timeLeft} setTimeLeft={setTimeLeft} firstTimeLeft={firstTimeLeft} setFirstTimeLeft={setFirstTimeLeft} />
                        </h4>) : null
                    }

                    {firstTimeLeft <= 0 && timeLeft > 0 ? (
                        <div className="text-center mb-3">Word to find: <b>{randomWord}</b></div>
                    ) : null}
                    {
                        firstTimeLeft > 0 ?
                            (
                                <div className="text-center mb-3">Memorize the words!</div>
                            ) : null
                    }
                </>
            ) : null}
            {
                winner ? (
                    <>
                        <h3 className="alert alert-success text-center">You won!</h3>
                        <div className="m-auto">
                            <img className="rounded mx-auto d-block" src={alien} width="250px" alt="alien" />
                        </div>
                    </>
                ) : null
            }
            {
                !winner && timeLeft <= 0 ? (
                    <>
                        <h3 className="alert alert-danger text-center">You lose :(</h3>
                        <div className="m-auto">
                            <img className="rounded mx-auto d-block" src={pikachu} width="250px" alt="pikachu" />
                        </div>
                    </>
                ) : null
            }
            {timeLeft > 0 && !gameFinish ?
                (
                    <div className="row mt-5">
                        <div className="col-4 text-center p-1">
                            <div>
                                <button onClick={handleClickButton} ref={ref[0]} id="1" className="btn btn-primary text-primary w-100 mt-1 height_cards cards_text">
                                    <span className="text-white" id="1">1</span>
                                    {noText[0].value ? null : palabras[0]}
                                </button>
                            </div>
                            <div>
                                <button onClick={handleClickButton} ref={ref[3]} id="4" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                    <span className="text-white" id="4">4</span>
                                    {noText[3].value ? null : palabras[3]}
                                </button>
                            </div>
                            {
                                option !== 'facil' ? (
                                    <div>
                                        <button onClick={handleClickButton} ref={ref[6]} id="7" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                            <span className="text-white" id="7">7</span>
                                            {noText[6].value ? null : palabras[6]}
                                        </button>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="col-4 text-center p-1">
                            <div>
                                <button onClick={handleClickButton} ref={ref[1]} id="2" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                    <span className="text-white" id="2">2</span>
                                    {noText[1].value ? null : palabras[1]}
                                </button>
                            </div>
                            <div>
                                <button onClick={handleClickButton} ref={ref[4]} id="5" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                    <span className="text-white" id="5">5</span>
                                    {noText[4].value ? null : palabras[4]}
                                </button>
                            </div>
                            {
                                option !== 'facil' ? (
                                    <div>
                                        <button onClick={handleClickButton} ref={ref[7]} id="8" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                            <span className="text-white" id="8">8</span>
                                            {noText[7].value ? null : palabras[7]}
                                        </button>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="col-4 text-center p-1">
                            <div>
                                <button onClick={handleClickButton} ref={ref[2]} id="3" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                    <span className="text-white" id="3">3</span>
                                    {noText[2].value ? null : palabras[2]}
                                </button>
                            </div>
                            <div>
                                <button onClick={handleClickButton} ref={ref[5]} id="6" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                    <span className="text-white" id="6">6</span>
                                    {noText[5].value ? null : palabras[5]}
                                </button>
                            </div>
                            {
                                option !== 'facil' ? (
                                    <div>
                                        <button onClick={handleClickButton} ref={ref[8]} id="9" className="btn btn-primary text-primary w-100 mt-1 height_cards">
                                            <span className="text-white" id="9">9</span>
                                            {noText[8].value ? null : palabras[8]}
                                        </button>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                ) :
                (
                    <div className="text-center mt-5 m-auto">
                        <p className="mt-3">
                            Do you want to restart?
                        </p>
                        <button onClick={handleReset} className="btn btn-info w-auto m-auto mt-2">
                            Reset
                        </button>
                    </div>
                )
            }
            {
                timeLeft <= 0 || winner ? (
                    <>
                        <div className="m-auto w-75">
                            <h5 className="mt-3">Game score:</h5>
                            <div>
                                <p className="mb-2 mt-3">Time left: {tiempoRestante} seconds</p>
                                <p>Attempts: {intentos}</p>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div>
    )
}