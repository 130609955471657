import React, { useEffect, useState } from 'react'

export const Time = ({ timeLeft, setTimeLeft, firstTimeLeft, setFirstTimeLeft }) => {

    const [gameFinish, setGameFinish] = useState(false);

    let timer;

    const myTimer = () => {

        let timesRun = 0;

        timer = setInterval(() => {

            if (timesRun === timeLeft) {
                setGameFinish(true);
                clearInterval(myTimer);
            } else {
                if (Number(firstTimeLeft) <= 0) {
                    timesRun++;
                    setTimeLeft(time => time - 1);
                } else {
                    setFirstTimeLeft(firstTimeLeft => firstTimeLeft - 1);
                }
            }

            // console.log("hola")

        }, 1000);

    }

    useEffect(() => {
        timer = myTimer;
        timer();
        return () => {
            clearInterval(timer);
        }
    }, [timeLeft, firstTimeLeft])

    return (
        <>
            {Number(firstTimeLeft) <= 0
                ?
                (
                    <>
                        <div>Time left:</div>
                        <div>{timeLeft}</div>
                    </>
                )
                :
                (<div>{firstTimeLeft}</div>)
            }

        </>
    )
}
