import React, { useEffect, useState } from 'react'
import { Game } from './Game';
import Swal from 'sweetalert2';
import brain from '../assets/brain.png';
import palabrasJson from '../data/palabras.json';

let palabras = [];

export const Option = () => {

    const [option, setOption] = useState('');
    const [showContent, setShowContent] = useState(true);

    const handleClickButtonFacil = () => {
        setOption("facil");
        setShowContent(false);
        palabras = palabrasJson.animales;
        shuffle(palabras);
        let palabrasTemp = [];
        for (let index = 0; index < 6; index++) {
            palabrasTemp.push(palabras[index]);
        }
        palabras = palabrasTemp;
    }

    const handleClickButtonNormal = () => {
        setOption("normal");
        setShowContent(false);
        palabras = palabrasJson.animales;
        shuffle(palabras);
        let palabrasTemp = [];
        for (let index = 0; index < 9; index++) {
            palabrasTemp.push(palabras[index]);
        }
        palabras = palabrasTemp;
    }

    const handleClickButtonDificil = () => {
        setOption("dificil");
        setShowContent(false);
        palabras = palabrasJson.palabrasCa;
        shuffle(palabras);
        let palabrasTemp = [];
        for (let index = 0; index < 9; index++) {
            palabrasTemp.push(palabras[index]);
        }
        palabras = palabrasTemp;
    }

    const handleClickButtonExtremo = () => {
        setOption("extremo");
        setShowContent(false);
        palabras = palabrasJson.animales;
        shuffle(palabras);
        let palabrasTemp = [];
        for (let index = 0; index < 9; index++) {
            palabrasTemp.push(palabras[index]);
        }
        palabras = palabrasTemp;
    }

    const handleClickButtonResetPoints = () => {

        Swal.fire({
            title: 'Reset stats?',
            text: "You will not be able to recover them.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Reset stats'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your stats have been reset.',
                    'success'
                ).then(() => {

                    localStorage.removeItem("partidasFacilesJugadas");
                    localStorage.removeItem("partidasFacilesGanadas");

                    localStorage.removeItem("partidasNormalesJugadas");
                    localStorage.removeItem("partidasNormalesGanadas");

                    localStorage.removeItem("partidasDificilesJugadas");
                    localStorage.removeItem("partidasDificilesGanadas");

                    localStorage.removeItem("partidasExtremasJugadas");
                    localStorage.removeItem("partidasExtremasGanadas");

                    window.location.reload();
                })
            }
        })
    }

    function shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        while (0 !== currentIndex) {

            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    let partidasFacilesJugadas = localStorage.getItem("partidasFacilesJugadas");
    let partidasFacilesGanadas = localStorage.getItem("partidasFacilesGanadas");

    const [porcentajePartidasFaciles, setPorcentajePartidasFaciles] = useState(0);

    let partidasNormalesJugadas = localStorage.getItem("partidasNormalesJugadas");
    let partidasNormalesGanadas = localStorage.getItem("partidasNormalesGanadas");

    const [porcentajePartidasNormales, setPorcentajePartidasNormales] = useState(0);

    let partidasDificilesJugadas = localStorage.getItem("partidasDificilesJugadas");
    let partidasDificilesGanadas = localStorage.getItem("partidasDificilesGanadas");

    const [porcentajePartidasDificiles, setPorcentajePartidasDificiles] = useState(0);

    let partidasExtremasJugadas = localStorage.getItem("partidasExtremasJugadas");
    let partidasExtremasGanadas = localStorage.getItem("partidasExtremasGanadas");

    const [porcentajePartidasExtremas, setPorcentajePartidasExtremas] = useState(0);

    let partidasTotalesJugadas = Number(partidasFacilesJugadas) + Number(partidasNormalesJugadas) + Number(partidasDificilesJugadas) + Number(partidasExtremasJugadas);;

    let partidasTotalesGanadas = Number(partidasFacilesGanadas) + Number(partidasNormalesGanadas) + Number(partidasDificilesGanadas) + Number(partidasExtremasGanadas);;

    const [porcentajePartidasTotales, setPorcentajePartidasTotales] = useState(0);

    useEffect(() => {
        if (!partidasFacilesGanadas) {
            setPorcentajePartidasFaciles(0);
        } else {
            setPorcentajePartidasFaciles((((partidasFacilesGanadas / partidasFacilesJugadas) * 100)).toFixed(2));
        }

        if (!partidasNormalesGanadas) {
            setPorcentajePartidasNormales(0);
        } else {
            setPorcentajePartidasNormales((((partidasNormalesGanadas / partidasNormalesJugadas) * 100)).toFixed(2));
        }

        if (!partidasDificilesGanadas) {
            setPorcentajePartidasDificiles(0);
        } else {
            setPorcentajePartidasDificiles((((partidasDificilesGanadas / partidasDificilesJugadas) * 100)).toFixed(2));
        }

        if (!partidasExtremasGanadas) {
            setPorcentajePartidasExtremas(0);
        } else {
            setPorcentajePartidasExtremas((((partidasExtremasGanadas / partidasExtremasJugadas) * 100)).toFixed(2));
        }

        setPorcentajePartidasTotales(((partidasTotalesGanadas / partidasTotalesJugadas) * 100).toFixed(2))

    }, [])

    return (
        <>
            <div className="container div_global">
                <h1 className="text-center mt-3 title_memory_game">Word Memory Game</h1>

                {option === "facil" ? (
                    <Game palabras={palabras} option={option} timeLeftParameter={60} />
                ) : null}
                {option === "normal" ? (
                    <Game palabras={palabras} timeLeftParameter={80} />
                ) : null}
                {option === "dificil" ? (
                    <Game palabras={palabras} option={option} timeLeftParameter={80}  />
                ) : null}
                {option === "extremo" ? (
                    <Game palabras={palabras} option={option} timeLeftParameter={50} />
                ) : null}
                {
                    showContent ? (
                        <div>
                            <div className="container mb-5">
                                <h6 className="text-center mt-4">Choose a mode</h6>
                                <div className="container">
                                    <div className="row mt-3">
                                        <div className="col-6 mt-3 text-center">
                                            <button onClick={handleClickButtonFacil} className="btn btn-success">Easy</button>
                                        </div>
                                        <div className="col-6 mt-3 text-center">
                                            <button onClick={handleClickButtonNormal} className="btn btn-info">Normal</button>
                                        </div>
                                        <div className="col-6 mt-3 text-center">
                                            <button onClick={handleClickButtonDificil} className="btn btn-danger">Hard</button>
                                        </div>
                                        <div className="col-6 mt-3 text-center">
                                            <button onClick={handleClickButtonExtremo} className="btn btn-dark">Extreme</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="container mt-5 card pt-3 bg-aqua">
                                    <h5 className="font-weight-bold">Your score:</h5>
                                    <hr />
                                    <div>
                                        <p>Easy Mode</p>
                                        <ul>
                                            <li>Games played: {localStorage.getItem("partidasFacilesJugadas") === null ? 0 : (
                                                <>
                                                    {partidasFacilesJugadas}
                                                </>
                                            )}</li>
                                            <li>Games won: {localStorage.getItem("partidasFacilesGanadas") === null ? 0 : (
                                                <>
                                                    {partidasFacilesGanadas}
                                                </>
                                            )}</li>
                                            <li>{porcentajePartidasFaciles ? (<span>Winning percentage: {porcentajePartidasFaciles}%</span>) : (<span>Winning percentage: 0%</span>)}</li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>Normal Mode</p>
                                        <ul>
                                            <li>Games played: {localStorage.getItem("partidasNormalesJugadas") === null ? 0 : (
                                                <>
                                                    {partidasNormalesJugadas}
                                                </>
                                            )}</li>
                                            <li>Games won: {localStorage.getItem("partidasNormalesGanadas") === null ? 0 : (
                                                <>
                                                    {partidasNormalesGanadas}
                                                </>
                                            )}</li>
                                            <li>{porcentajePartidasNormales ? (<span>Winning percentage: {porcentajePartidasNormales}%</span>) : <span>Winning percentage: 0%</span>}</li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>Hard mode</p>
                                        <ul>
                                            <li>Games played: {localStorage.getItem("partidasDificilesJugadas") === null ? 0 : (
                                                <>
                                                    {partidasDificilesJugadas}
                                                </>
                                            )}</li>
                                            <li>Games won: {localStorage.getItem("partidasDificilesGanadas") === null ? 0 : (
                                                <>
                                                    {partidasDificilesGanadas}
                                                </>
                                            )}</li>
                                            <li>{porcentajePartidasDificiles ? (<span>Winning percentage: {porcentajePartidasDificiles}%</span>) : (<span>Winning percentage: 0%</span>)}</li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>Extreme mode</p>
                                        <ul>
                                            <li>Games played: {localStorage.getItem("partidasExtremasJugadas") === null ? 0 : (
                                                <>
                                                    {partidasExtremasJugadas}
                                                </>
                                            )}</li>
                                            <li>Games won: {localStorage.getItem("partidasExtremasGanadas") === null ? 0 : (
                                                <>
                                                    {partidasExtremasGanadas}
                                                </>
                                            )}</li>
                                            <li>{porcentajePartidasExtremas ? (<span>Winning percentage: {porcentajePartidasExtremas}%</span>) : (<span>Winning percentage: 0%</span>)}</li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>Totals</p>
                                        <ul>
                                            <li>Games played: {partidasTotalesJugadas}
                                            </li>
                                            <li>Games won: {partidasTotalesGanadas}
                                            </li>
                                            <li>{!isNaN(porcentajePartidasTotales) ? (<span>Winning rate: {porcentajePartidasTotales}%</span>) : (<span>Winning rate: 0%</span>)}</li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <div className="mb-3">
                                        <button onClick={handleClickButtonResetPoints} className="btn btn-dark">Reset stats</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </>
    )
}
